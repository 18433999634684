import React from "react"
import * as styles from "./styles.module.scss"
import { graphql } from "gatsby"
import { BLOCKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Container, Main } from "components"

const options = {
	renderNode: {
		[BLOCKS.HEADING_2]: (node, children) => <h2>{children}</h2>,
		[BLOCKS.HEADING_3]: (node, children) => <h3>{children}</h3>,
		[BLOCKS.HEADING_4]: (node, children) => <h4>{children}</h4>,
		[BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
		[BLOCKS.OL_LIST]: (node, children) => <ol>{children}</ol>,
		[BLOCKS.UL_LIST]: (node, children) => <ul>{children}</ul>,
		[BLOCKS.LIST_ITEM]: (node, children) => <li>{children}</li>,
		[BLOCKS.EMBEDDED_ASSET]: (node) => {
			const { gatsbyImageData } = node.data.target
			if (!gatsbyImageData) return null
			return <GatsbyImage image={gatsbyImageData} alt="venrup-blog-post-image" className={styles.image} />
		},
	},
}

export default function BlogPost({ data, location }) {
	return (
		<Main>
			<Container>
				<div className={styles.post}>
					{data?.allContentfulEntry?.nodes.map((post) => {
						if (post?.slug === location.pathname.split("/")[2]) {
							const featuredImage = getImage(post?.featured_image)
							const { body } = post
							return (
								<div key={post?.id}>
									<div className="featured-image">
										<GatsbyImage image={featuredImage} alt="blog featured image" />
									</div>
									<h1 className={styles.postTitle}>{post.title}</h1>
									<div>{body && renderRichText(body, options)}</div>
								</div>
							)
						}
						return false
					})}
				</div>
			</Container>
		</Main>
	)
}

export const query = graphql`
	query SingleBlogPost {
		allContentfulEntry {
			nodes {
				... on ContentfulVenrupBlogPost {
					id
					title
					date_time
					slug
					featured_image {
						id
						gatsbyImageData
					}
					body {
						raw
						references {
							... on ContentfulAsset {
								contentful_id
								__typename
								gatsbyImageData
							}
						}
					}
				}
			}
		}
	}
`
